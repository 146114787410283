<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="tableHeaders"
        :items="dataTable"
        :loading="loading"
        disable-pagination
        hide-default-footer
        class="elevation-0"
        id="test"
      >
        <template #item="{ item }">
          <tr :id="getDate(item.date)">
            <td>{{ dataTable.indexOf(item) + 1 }}</td>
            <td>
              <v-alert
                :color="infoAbsent(item.type_in).color"
                border="left"
                colored-border
                class="mt-2 mb-2 caption"
                height="80"
                elevation="2"
              >
                <div class="font-weight-bold">
                  {{ infoAbsent(item.type_in).name }}
                </div>
                <div v-if="item.real_in">
                  {{ item.real_in.split(" ")[1].substr(0, 5) }}
                </div>
                <div>
                  {{ item.desc_in }}
                </div>
              </v-alert>
            </td>
            <td>
              <v-alert
                :color="infoAbsent(item.type_out).color"
                border="left"
                colored-border
                height="80"
                class="mt-2 mb-2 caption"
                elevation="2"
              >
                <div class="font-weight-bold">
                  {{ infoAbsent(item.type_out).name }}
                </div>
                <div v-if="item.real_out">
                  {{ item.real_out.split(" ")[1].substr(0, 5) }}
                </div>
                <div>
                  {{ item.desc_out }}
                </div>
              </v-alert>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import i18n from "@/i18n";
import { reportAbsent, absentException } from "@/api/admin/absent/absent";
import { colorAbsent } from "@/utils/master";
import moment from "moment/moment";

export default {
  props: {
    idPerson: String,
    idMonth: String,
    year: String
  },
  mounted() {
    this.getData();
  },
  watch: {
    idMonth() {
      if (this.idMonth) this.getData();
    },
    dataTable(size) {
      if (size.length) {
        setTimeout(() => {
          this.scroll();
        }, 500);
      }
    }
  },
  data() {
    return {
      currentPermanent: "#87dafc",
      masterAbsent: [],
      loading: false,
      tableHeaders: [
        {
          text: i18n.t("journal.table.date"),
          align: "left",
          value: "date",
          width: 10
        },
        { text: i18n.t("absent.absent_in"), value: "in", width: 420 },
        {
          text: i18n.t("absent.absent_out"),
          value: "out",
          width: 420
        }
      ],
      dataTable: [],
      currentDate: moment().format("DD")
    };
  },
  methods: {
    getDate(date) {
      return moment(date).format("DD");
    },
    scroll() {
      const el = document.getElementById(this.currentDate);
      el.scrollIntoView({ behavior: "smooth", block: "center" });
      el.style.background = this.currentPermanent;
    },
    infoAbsent: type => colorAbsent(type),
    async getData() {
      this.loading = true;
      this.dataTable = [];
      const bodyAbsent = {
        person: this.idPerson,
        month: this.idMonth,
        year: this.year
      };
      if (this.masterAbsent.length > 0) {
        const master = await absentException();
        if (master.data.code) {
          this.masterAbsent = master.data.data;
        } else this.snackBar(false, master.data.message);
      }
      const listAbsent = await reportAbsent(bodyAbsent);
      if (listAbsent.data.code) this.dataTable = listAbsent.data.data;
      else this.snackBar(false, listAbsent.data.message);
      this.loading = false;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    }
  }
};
</script>

<style lang="scss">
@keyframes highlight {
  0% {
    background-color: #27ace2;
  }
  50% {
    background-color: #87dafc;
  }
}
</style>
